<template>

    <span :class="[this.isViewMode ? 'card-link-text' : 'card-link-text-disable']" role="link" @click.stop="openDocument">
        {{ text }}
    </span>

</template>

<script>
export default {
    name: "DocumentLink",
    props: {
        id: String,
        type: String,
        text: String,
        common: {
            type: Boolean,
            default: false
        },
        isViewMode: {
            type: Boolean,
            default: true
        },
        isDirectLink: {
            type: Boolean,
            default: true
        },
        executionId: {
            type: String,
            default: null
        }
    },
    methods: {
        openDocument() {
            if (!this.isViewMode)
                return;

            if (!!this.id && this.id.length > 0 && !!this.type && this.type.length > 0) {
                this.$emit("on-link-clicked");

                if (this.isDirectLink) {
                    this.$eventBus.$emit('open-document', { id: this.id, type: this.type, common: this.common });
                }
                // Ссылка не прямая, т.е. в экземпляре компонента is-direct-link false
                else {
                    if (this.executionId)
                        // открытие документ из карточки исполнения. ОБЯЗАТЕЛЬНО нужен идентификатор исполнения (в экземпляре компонента :execution-id)
                        this.$eventBus.$emit('open-linked-document', { type: this.type, common: this.common, linkType: 'RTELink', documentId: this.id, executionId: this.executionId });
                    else
                        // открытие документ из вкладки Контроль.
                        this.$eventBus.$emit('open-linked-document', { type: this.type, common: this.common, linkType: 'NotDirectLink', documentId: this.id });
                }
            }
            else
                this.$notify.warning(this.$t('Данные_в_ссылке_на_документ_не_соответствуют_ожидаемому_формату'));
        }
    }
}
</script>